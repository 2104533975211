.sidebar{
  flex:1;
  height:100vh;
  background-color: #17171a;
  color: white;
  position: sticky;
  top: 50px;
}
.sidebarWrapper{
  padding: 20px;
  color: #555;

}
.sidebarMenu{
  margin-top: 10px;
}

.sidebarTitle{
  font-size: 13px;
  color: rgba(187,186,186);
}

.sidebarList{
  list-style: none;
  padding: 5px;
}
.sidebarListItem{
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 10px;
}

.sidebarListItem.active, .sidebarListItem:hover{
  background-color: rgba(240,240,255)
}
.sidebarIcon{
  margin-right: 5px;
  font-size: 20px !important;
}
