.home{
  flex: 4;  
  background-color: #17171a;
  color: white;
}

.homeWidget{
  display: flex;
  margin: 20px;
}

