.container{
  display: flex;
  margin-top: 10px;  
}
.link{
  text-decoration: none;
  color: inherit;
}

body{
  background-color: #17171a;
}