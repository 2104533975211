.topbar{
  width: 100%;
  height: 150px;  
  position : sticky;
  top: 0;
  margin-bottom: 0;
  z-index: 999;
  background-color: #17171a;
  color: white;
}

.topbarWrapper{
  height:100%;
  padding: 0px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo{
  font-weight: bold;
  font-size: 30px;
  color:white;
  cursor: pointer;
  background-color: #17171a;
  text-decoration: underline;
}


.topRigth{
  display: flex;
  align-items: center;
}
.topIconBadge{
  width:15px;
  height: 15px;
  position: absolute;
  top: -5px;
  right: 0px;
  background-color: red;
  color: white;
  border-radius:50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size:10px;

}

.topAvatar{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
}